import Carousel from "react-multi-carousel";
import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import { useRefinementList } from "react-instantsearch";
import queryString from "query-string";
import categoryItems from "./categoryItems.json";
import { capturePosthogLink } from "../../utilities/index";
import LazyLoadingImage from "../lazy-loading-image";
import { useClearRefinements } from "react-instantsearch";

const CustomRefinementList = (props) => {
  const { items, refine } = useRefinementList({
    attribute: props.attribute,
  });
  const refinedItem = items.find((val) => {
    return val.isRefined === true;
  });

  const { refine: clearRefinements, canRefine } = useClearRefinements({
    excludedAttributes: ['root_types'],
  });

  const posthog = props?.posthog;

  const [infinite, setInfinite] = useState(false);

  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    const arrayOfCatLabel = categoryItems?.map((item) => item.label) || [];
    const activeCat = parsed?.["category[0]"];
    const catIndex = arrayOfCatLabel?.indexOf(activeCat);

    // if (
    //   carouselRef?.current &&
    //   catIndex >= 0 &&
    //   typeof window !== "undefined" &&
    //   window.innerWidth < 1200
    // ) {
    //   setInfinite(true);

    //   // Move to the initial slide using the goToSlide method
    //   carouselRef.current?.goToSlide(catIndex);
    // }

    if (
      parsed?.["category[0]"] &&
      refinedItem !== "undefined" &&
      refinedItem?.label &&
      refinedItem?.label !== parsed?.["category[0]"]
    ) {
      refine(refinedItem?.label);
      refine(parsed?.["category[0]"]);
    }

    function handleResize() {
      // Update the state or perform any other actions when the
      // browser is resized
      if (
        carouselRef?.current &&
        catIndex >= 0 &&
        typeof window !== "undefined" &&
        window.innerWidth < 1200
      ) {
        carouselRef.current?.goToSlide(catIndex);
        setInfinite(true);
      } else {
        setInfinite(false);
        carouselRef.current?.goToSlide(0);
      }
    }

    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [parsed]);

  // const CustomRightArrow = ({ onClick, ...rest }) => {
  //   const {
  //     onMove,
  //     carouselState: { currentSlide, deviceType },
  //   } = rest;
  //   // onMove means if dragging or swiping in progress.
  //   return (
  //     <>
  //       {props.number === 3 ? (
  //         <button
  //           className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
  //           onClick={() => onClick()}
  //         />
  //       ) : (
  //         <button
  //           className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
  //           id="green__button"
  //           onClick={() => onClick()}
  //         />
  //       )}
  //     </>
  //   );
  // };
  // const CustomLeftArrow = ({ onClick, ...rest }) => {
  //   const {
  //     onMove,
  //     carouselState: { currentSlide, deviceType },
  //   } = rest;
  //   // onMove means if dragging or swiping in progress.
  //   return (
  //     <>
  //       <button
  //         className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
  //         id="green__button"
  //         onClick={() => onClick()}
  //       />
  //     </>
  //   );
  // };

  const carouselRef = useRef(null);

  return (
    <div>
      <Carousel
        responsive={{
          extraLargeDesktop: {
            breakpoint: { max: 9999, min: 2000 },
            items: 9,
          },
          largeDesktop: {
            breakpoint: { max: 1999, min: 1500 },
            items: 9,
          },
          desktop: {
            breakpoint: { max: 1499, min: 1330 },
            items: 9,
          },
          largeTablet: {
            breakpoint: { max: 1329, min: 1200 },
            items: 9,
          },
          tablet: {
            breakpoint: { max: 1199, min: 768 },
            items: 5.5,
          },
          largeMobile: {
            breakpoint: { max: 767, min: 376 },
            items: 2.15,
          },
          mobile: {
            breakpoint: { max: 375, min: 0 },
            items: 2.15,
          },
        }}
        autoPlay={false}
        shouldResetAutoplay={false}
        partialVisible={false}
        arrows={false}
        draggable={false}
        itemClass="carousel-item-padding-10-px"
        ref={carouselRef}
        slidesToSlide={2}
        customTransition="transform 500ms ease-in-out"
        containerClass="carousel-container-scroll"
      >
        {categoryItems?.map((item) => {
          return (
            <span key={item.label}>
              <div
                id={`category-${item.label}`}
                className={`div-one card__category ${
                  refinedItem?.label === item.label ? "active__card" : ""
                }`}
                onClick={() => {
                  // Clear all refinements (subcategories) before applying the new category
                  if (!refinedItem) {
                    clearRefinements();
                    refine(item.label);
                  }
                  else {
                    clearRefinements();
                  }

                  const newUrl = `/shop/${props.storeId}?category%5B0%5D=${item.label}`;
                  navigate(newUrl);

                  capturePosthogLink({
                    eventName: "Product category clicked",
                    title: item.title,
                    to: newUrl,
                    posthog,
                  });

                }}
              >
                {/* <img src={item.image} /> */}
                <LazyLoadingImage src={item.image} alt={"icon" + item.title} />
              </div>
              <span
                className={`cat-title ${
                  refinedItem?.label === item.label ? "active__text" : ""
                }`}
              >
                {item.title}
              </span>
            </span>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CustomRefinementList;
