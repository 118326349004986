import React, { useCallback } from "react";
import {
  RefinementList,
  ClearRefinements,
  useRange,
} from "react-instantsearch";
import {
  orderBy as _orderBy,
  isFinite as _isFinite,
  capitalize as _capitalize,
  startCase as _startCase,
} from "lodash";

import ArrowIcon from "../../../elements/icons/Arrow";
import RangeSlider from "../range-slider";
import * as styles from "../productcategory.module.css";
import Button from "../../../elements/button/Button";

const FilterContent = ({ hide, onToggleDrawer, specialsLookup }) => {
  const [activeIndices, setActiveIndices] = React.useState(new Set());

  const handleOnClick = (index) => {
    const newIndices = new Set(activeIndices);
    if (activeIndices.has(index)) {
      newIndices.delete(index);
    } else {
      newIndices.add(index);
    }
    setActiveIndices(newIndices);
  };

  const transformItemsCapitalizeExceptCBD = useCallback((items) => {
    return _orderBy(items, "label", "asc").map((item) => ({
      ...item,

      label:
        item.label !== "cbd"
          ? _startCase(item.label.toLowerCase())
          : item.label.toUpperCase(),
    }));
  }, []);

  const transformItemsSpecialsLookup = useCallback(
    (items) => {
      return items.map((item) => {
        return {
          ...item,
          label: specialsLookup[Number(item.label)]
            ? specialsLookup[Number(item.label)].title
            : item.label,
        };
      });
    },
    [specialsLookup]
  );

  return (
    <div className={`search-panel__filters ${hide ? "hide_mobile" : ""}`}>
      <div>Filter Results:</div>

      <h3 onClick={() => handleOnClick(1)}>
        Strain Type
        <ArrowIcon
          className={`${styles.arrow__icon} ${
            activeIndices.has(1) ? styles.opensvg : ""
          }`}
        />
      </h3>

      <div
        className={`${styles.item__toggle} ${
          activeIndices.has(1) ? styles.open : ""
        }`}
      >
        <RefinementList
          attribute="category"
          className={"item__option item__category__options"}
          transformItems={transformItemsCapitalizeExceptCBD}
        />
      </div>

      <hr />
      { typeof window !== 'undefined' && (window.location.search.indexOf('category')) <= 0 ? null :
        <>
        <h3 onClick={() => handleOnClick(2)}>
          Sub-category{" "}
          <ArrowIcon
            className={`${styles.arrow__icon} ${
              activeIndices.has(2) ? styles.opensvg : ""
            }`}
          />
        </h3>
      

      <div
        className={`${styles.item__toggle} ${
          activeIndices.has(2) ? styles.open : ""
        }`}
      >
        <div className={styles.item__brand_filter} id={styles.item__sub_filter}>
          <RefinementList
            attribute="brand_subtype"
            transformItems={transformItemsCapitalizeExceptCBD}
          />
        </div>
      </div>
      <hr />
      </>
      }

      <h3 onClick={() => handleOnClick(5)}>
        Specials{" "}
        <ArrowIcon
          className={`${styles.arrow__icon} ${
            activeIndices.has(5) ? styles.opensvg : ""
          }`}
        />
      </h3>
      <div
        className={`${styles.item__toggle} ${
          activeIndices.has(5) ? styles.open : ""
        }`}
      >
        <div
          className={styles.item__brand_filter}
          id={styles.item__specials_filter}
        >
          <RefinementList
            attribute="applicable_special_ids"
            transformItems={transformItemsSpecialsLookup}
          />
        </div>
      </div>

      {/*<h3>THC Potency</h3>
      <div className="item__option">
        <Rheostat min={1} max={100} values={[1, 100]} />
        <div className="sider__number_container">
          <div className="slider__number">10%</div>
          <div className="slider__number ">40%</div>
        </div>
      </div>
      <h3>CBD Potency</h3>
      <div className="item__option">
        <Rheostat min={1} max={100} values={[1, 100]} />
        <div className="sider__number_container">
          <div className="slider__number">10%</div>
          <div className="slider__number ">40%</div>
        </div>
      </div>
      */}
      <hr />
      <h3 onClick={() => handleOnClick(6)}>
        Price
        <ArrowIcon
          className={`${styles.arrow__icon} ${
            activeIndices.has(6) ? styles.opensvg : ""
          }`}
        />
      </h3>
      <div
        id={styles.rheostat_container}
        className={`${styles.item__toggle} ${
          activeIndices.has(6) ? styles.open : ""
        }`}
      >
        <div className="item__option">
          <RangeSlider attribute="bucket_price" />
        </div>
      </div>
      <hr />
      {/* <h3 onClick={() => handleOnClick(3)}>
        Categories
        <ArrowIcon
          className={`${styles.arrow__icon} ${
            activeIndices.has(3) ? styles.opensvg : ""
          }`}
        />
      </h3>
      <div
        className={`${styles.item__toggle} ${
          activeIndices.has(3) ? styles.open : ""
        }`}
      >
        <RefinementList
          transformItems={(items) => {
            return items
              .sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();
                if (labelA < labelB) {
                  return -1;
                }
                if (labelA > labelB) {
                  return 1;
                }
                return 0;
              })
              .filter((item) => {
                return !item.label.includes(":");
              })
              .map((item) => ({
                ...item,
                label: janeCategoryLookupBySlug[item.label]?.name,
              }));
          }}
          attribute="root_types"
          className="item__option"
        />
      </div>
      <hr /> */}

      <h3 onClick={() => handleOnClick(4)}>
        Brands{" "}
        <ArrowIcon
          className={`${styles.arrow__icon} ${
            activeIndices.has(4) ? styles.opensvg : ""
          }`}
        />
      </h3>
      <div
        className={`${styles.item__toggle} ${
          activeIndices.has(4) ? styles.open : ""
        }`}
      >
        <div className={styles.item__brand_filter}>
          <RefinementList
            attribute="brand"
            limit="20"
            showMore={true}
            showMoreLimit={100}
            transformItems={transformItemsCapitalizeExceptCBD}
            // transformItems={(items) => {
            //   return items.sort((a, b) => {
            //     const labelA = a.label.toUpperCase();
            //     const labelB = b.label.toUpperCase();
            //     if (labelA < labelB) {
            //       return -1;
            //     }
            //     if (labelA > labelB) {
            //       return 1;
            //     }
            //     return 0;
            //   });
            // }}
          />
        </div>
      </div>

      {/*<h3>Reviews</h3>
      <RefinementList attribute="aggregate_rating" className="item__option" />
   <h3>Price</h3>
      <RefinementList attribute="bucket_price" className="item__option" />
      <h3>Kind</h3>
      <RefinementList attribute="kind" />*/}
      <div className="item__button_container">
        {/* <Button theme="tgsclear" label="Clear Filters"></Button> */}
        <ClearRefinements
          excludedAttributes={[]}
          translations={{
            resetButtonText: "Clear Filters",
          }}
          classNames={{ button: "button-module--tgsclear" }}
        />
        <div>
          <Button
            theme="tgsfilter"
            label="Show Results"
            onClick={onToggleDrawer}
            id="drawer-toggle-button"
          />
        </div>
      </div>
    </div>
  );
};

export default FilterContent;
